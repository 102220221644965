import React from 'react';
import { Progress, Typography } from 'antd';
import { UploadInfoBody } from './upload-info-body';
import { formatBytes } from '@shared/utils/format-bytes';
import { ProgressContainer } from './progress-container';
import { UploadInfoContainer } from './upload-info-container';
import { calculatePercentage } from '@shared/utils/calculate-percentage';

export const UploadInfoCard: React.FC<{ uploadInfo: any }> = ({
  uploadInfo,
}) => {
  return typeof uploadInfo === 'object' ? (
    <UploadInfoBody>
      <ProgressContainer>
        <Progress
          size={72}
          type="circle"
          showInfo={false}
          percent={calculatePercentage(
            uploadInfo.bytesSent,
            uploadInfo.totalSize,
          )}
        />
      </ProgressContainer>
      <UploadInfoContainer>
        <Typography.Text>{formatBytes(uploadInfo.bytesSent)}</Typography.Text>
        <Typography.Text>/</Typography.Text>
        <Typography.Text>{formatBytes(uploadInfo.totalSize)}</Typography.Text>
      </UploadInfoContainer>
    </UploadInfoBody>
  ) : null;
};
