import { Skeleton } from 'antd';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';

export const TitleSkeleton = styled(Skeleton)(() => ({
  [`&.${prefixCls}-skeleton.${prefixCls}-skeleton-active`]: {
    [`& > .${prefixCls}-skeleton-content`]: {
      [`& > .${prefixCls}-skeleton-title`]: {
        margin: 0,
        height: 22,
        marginBottom: '.8rem',
      },
      [`& > .${prefixCls}-skeleton-paragraph`]: {
        margin: 0,
        '& > li': {
          height: 22,
        },
      },
    },
  },
}));
