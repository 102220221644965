import styled from '@emotion/styled';
import { builderCardSizes } from '@shared/constants/builderCardSizes';

export const CardHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size',
})(({ size }: { size: builderCardSizes }) => ({
  gap: '1rem',
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  flexDirection: [
    builderCardSizes.s12,
    builderCardSizes.s22,
    builderCardSizes.s14,
  ].includes(size)
    ? 'column'
    : 'row',
}));
