'use client';

import React from 'react';
import { Content } from './ui/content';
import { Builder } from '@shared/types/builder';
import { ExtraActions } from './ui/extra-actions';
import { RootContainer } from './ui/root-container';
import { useSelector, selectActiveItem } from '@shared/redux';
import { Actions } from '@widgets/mediakit/MediaKit/Common/Actions';
import { builderCardSizes } from '@shared/constants/builderCardSizes';

export function Link({
  data,
  index,
  dataGrid,
  dataServer,
  showSkeleton,
}: Builder.GridComponentProps) {
  const activeItem = useSelector(selectActiveItem);
  const activeSize = `${dataGrid.w}${dataGrid.h}` as builderCardSizes;

  const className = activeItem === dataGrid.i ? 'active-item' : '';

  return (
    <RootContainer key={dataGrid.i} data-grid={dataGrid} className={className}>
      <Content
        data={data}
        dataGrid={dataGrid}
        dataServer={dataServer}
        activeSize={activeSize}
        showSkeleton={showSkeleton}
      />
      <Actions
        index={index}
        dataGrid={dataGrid}
        extraActions={<ExtraActions data={data} dataGrid={dataGrid} />}
      />
    </RootContainer>
  );
}
