import styled from '@emotion/styled';
import { builderCardSizes } from '@shared/constants/builderCardSizes';

export const ImageContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size',
})(({ size }: { size: builderCardSizes }) => ({
  flexGrow: 1,
  overflow: 'hidden',
  alignItems: 'center',
  position: 'relative',
  justifyContent: 'center',
  border: '1px solid rgba(0, 0, 0, 0.06)',
  borderRadius: size === builderCardSizes.s24 ? '1.6rem' : '1rem',
}));
