'use client';

import React from 'react';
import Image from 'next/image';
import * as styles from './styles';
import dynamic from 'next/dynamic';
import { Box } from '@shared/ui/box';
import { Text } from '@shared/ui/typography';
import { Builder } from '@shared/types/builder';
import { Actions } from '../../../Common/Actions';
import { Skeleton, Tooltip, Typography } from 'antd';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { InfoCircleOutlined } from '@ant-design/icons';
import igIcon from '@shared/icons/instagram-light.svg';
import {
  selectActiveItem,
  selectMediakitTheme,
  selectMediakitThemeFonts,
  useSelector,
} from '@shared/redux';
import { useSkeletonLoader } from '@shared/hooks/useSkeletonLoader';
import { builderCardSizes } from '@shared/constants/builderCardSizes';
import { getInstagramAccountDetails } from '@widgets/mediakit/helpers';
import { fontVariants } from '@shared/constants/fontVariants';
import { defaultEndColor, defaultStartColor } from '@shared/constants';

// @ts-ignore
const Bar = dynamic(() => import('@ant-design/plots').then((mod) => mod.Bar), {
  ssr: false,
});

function Content({
  data,
  dataServer,
  disableSkeleton,
  activeSize: size,
}: {
  dataServer?: boolean;
  disableSkeleton?: boolean;
  data: Builder.GridComponentProps['data'];
  activeSize: builderCardSizes.s12 | builderCardSizes.s22;
}) {
  const match400 = useBreakpoint(400);
  const fonts = useSelector(selectMediakitThemeFonts);
  const showSkeleton = useSkeletonLoader(disableSkeleton);

  const theme = useSelector(selectMediakitTheme);
  const paletteColors = theme?.shuffled_palette?.split(',');
  let endColor = defaultEndColor;
  let startColor = defaultStartColor;
  if (Array.isArray(paletteColors)) {
    endColor = paletteColors?.[0];
  }
  if (theme?.primary_color) {
    startColor = theme.primary_color;
  }

  const detailsFunc = getInstagramAccountDetails()[data.name];
  const { title, tooltip } = detailsFunc({
    size,
  });

  const localData = (data?.data || []).reduce((acc: any, curr: any) => {
    acc.push(
      {
        sex: 'male',
        value: Number((curr.male * 100).toFixed(1)),
        age: curr.code === '65-' ? '>65' : curr.code,
      },
      {
        sex: 'female',
        age: curr.code === '65-' ? '>65' : curr.code,
        value: Number((curr.female * 100).toFixed(1)),
      },
    );

    return acc;
  }, []);

  return (
    <Box boxStyles={styles.cardBodyStyles}>
      <Box boxStyles={styles.cardBodyHeaderStyles}>
        <Skeleton
          active
          paragraph={false}
          title={styles.titleTitleProps}
          style={{ display: showSkeleton ? 'table' : 'none' }}
        />
        <Box
          boxStyles={Object.assign({}, styles.cardBodyHeaderDetailsStyles, {
            opacity: showSkeleton ? 0 : 1,
          })}
        >
          <Image src={igIcon} alt="Instagram" />
          <Text
            className={fontVariants.opt3}
            textProps={styles.cardBodyHeaderDetailsTitleStyles(size)}
          >
            {title}
          </Text>
        </Box>

        {tooltip && (
          <Tooltip title={tooltip}>
            <InfoCircleOutlined
              style={{
                fontSize: '2rem',
                color: '#00000073',
              }}
            />
          </Tooltip>
        )}
      </Box>
      <Box
        boxStyles={Object.assign({}, styles.cardBodyDescriptionStyles, {
          height: '100%',
          display: showSkeleton ? 'flex' : 'none',
          '& .mediakit-skeleton.mediakit-skeleton-element': {
            width: '100%',
            height: '100%',
            '& .mediakit-skeleton-image': {
              width: '100%',
              height: '100%',
            },
          },
        })}
      >
        <Skeleton.Node> </Skeleton.Node>
      </Box>
      <Box
        boxStyles={Object.assign({}, styles.cardBodyDescriptionStyles, {
          flexGrow: 1,
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
          flexDirection: 'column',
          display: showSkeleton ? 'none' : 'flex',
        })}
      >
        {dataServer && (
          <Box
            boxStyles={{
              zIndex: 10,
              width: '100%',
              height: '100%',
              position: 'absolute',
            }}
          />
        )}
        <Box
          boxStyles={{
            gap: 8,
            alignItems: 'center',
          }}
        >
          <Box
            boxStyles={{
              gap: 8,
              width: 'initial',
              alignItems: 'center',
            }}
          >
            <Box
              boxStyles={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor: endColor,
              }}
            />
            <Typography.Text className={fontVariants.opt3}>
              Male
            </Typography.Text>
          </Box>
          <Box
            boxStyles={{
              gap: 8,
              width: 'initial',
              alignItems: 'center',
            }}
          >
            <Box
              boxStyles={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor: startColor,
              }}
            />
            <Typography.Text className={fontVariants.opt3}>
              Female
            </Typography.Text>
          </Box>
        </Box>
        <React.Suspense>
          <Bar
            xField="age"
            axis={{
              y: {
                grid: true,
                gridLineWidth: 0.5,
                gridStroke: 'black',
                gridLineDash: [0, 0],
                gridStrokeOpacity: 0.15,
                labelFontFamily: fonts?.opt3.family_name,
              },
              x: {
                labelFontFamily: fonts?.opt3.family_name,
              },
            }}
            height={254}
            scale={{
              color: {
                palette: [endColor, startColor],
              },
            }}
            stack={false}
            style={{
              radiusTopLeft: 10,
              radiusTopRight: 10,
            }}
            yField="value"
            legend={false}
            marginLeft={0}
            tooltip={false}
            colorField="sex"
            seriesField="sex"
            data={{
              type: 'inline',
              value: localData || [],
            }}
            width={match400 ? 300 : 320}
          />
        </React.Suspense>
      </Box>
    </Box>
  );
}

export function SexAgeRatio({
  data,
  index,
  dataGrid,
  dataServer,
  disableSkeleton,
}: Builder.GridComponentProps) {
  const activeItem = useSelector(selectActiveItem);

  const activeSize = `${dataGrid.w}${dataGrid.h}` as
    | builderCardSizes.s22
    | builderCardSizes.s12;

  return (
    <Box
      key={dataGrid.i}
      data-grid={dataGrid}
      boxStyles={styles.cardStyles}
      className={activeItem === dataGrid.i ? 'active-item' : ''}
    >
      <Content
        data={data}
        dataServer={dataServer}
        activeSize={activeSize}
        disableSkeleton={disableSkeleton}
      />
      <Actions index={index} dataGrid={dataGrid} disableActions />
    </Box>
  );
}
