'use client';

import React from 'react';
import Image from 'next/image';
import { Builder } from '@shared/types/builder';
import { Actions } from '../../../Common/Actions';
import ytIcon from '@shared/icons/youtube-light.svg';
import { MKitCard } from '@shared/ui/mkit-common-card';
import { selectActiveItem, useSelector } from '@shared/redux';
import { useSkeletonLoader } from '@shared/hooks/useSkeletonLoader';
import { builderCardSizes } from '@shared/constants/builderCardSizes';
import { getYouTubeAccountDetails } from '@widgets/mediakit/helpers';

export function AccountComponent(props: Builder.GridComponentProps) {
  const {
    data,
    showSkeleton,
    disableSkeleton,
    dataGrid: { w, h },
  } = props;
  const activeItem = useSelector(selectActiveItem);
  const activeSize = `${w}${h}` as builderCardSizes;
  const fakeSkeleton = useSkeletonLoader(disableSkeleton);

  const detailsFunc = getYouTubeAccountDetails()[data.name];
  const { title, value } = detailsFunc({
    size: activeSize,
    value: data.value,
  });

  return (
    <MKitCard
      {...props}
      title={title}
      value={value}
      activeItem={activeItem}
      activeSize={activeSize}
      showSkeleton={fakeSkeleton || showSkeleton}
      icon={<Image src={ytIcon} alt="YouTube" />}
      allowedSizes={[builderCardSizes.s12, builderCardSizes.s22]}
      actions={
        <Actions
          index={props.index}
          dataGrid={props.dataGrid}
          allowedSizes={[builderCardSizes.s12, builderCardSizes.s22]}
        />
      }
    />
  );
}
