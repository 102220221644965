import React from 'react';
import Image from 'next/image';
import { Skeleton } from 'antd';
import { CardBody } from './card-body';
import { TitleInput } from './title-input';
import { DomainLink } from './domain-link';
import { CardHeader } from './card-header';
import { CardFooter } from './card-footer';
import { Text } from '@shared/ui/typography';
import linkIcon from '@shared/icons/link.svg';
import { Builder } from '@shared/types/builder';
import { TitleSkeleton } from './title-skeleton';
import { HeaderDetails } from './header-details';
import { LinkContainer } from './link-container';
import { DomainContainer } from './domain-container';
import { Layout as ILayout } from 'react-grid-layout';
import defaultLink from '@shared/icons/defaultLink.svg';
import { ErrorBoundary } from '@shared/ui/error-boundary';
import { fontVariants } from '@shared/constants/fontVariants';
import { FiniteStates } from '@shared/constants/finiteStates';
import ImageWithFallback from '@shared/ui/image-with-fallback';
import { CTitleContainer, STitleContainer } from './title-container';
import { selectUploadImageStates, useSelector } from '@shared/redux';
import { builderCardSizes } from '@shared/constants/builderCardSizes';

export const Content: React.FC<{
  dataGrid: ILayout;
  dataServer?: boolean;
  showSkeleton?: boolean;
  activeSize: builderCardSizes;
  data: Builder.GridComponentProps['data'];
}> = ({ data, dataGrid, dataServer, showSkeleton, activeSize: size }) => {
  const uploadImageStates = useSelector(selectUploadImageStates);

  const isLoadingImage = uploadImageStates[dataGrid.i] === 'uploadImage';
  const isLoading = uploadImageStates[dataGrid.i] === FiniteStates.PENDING;

  return (
    <CardBody size={size}>
      <CardHeader size={size}>
        <HeaderDetails size={size}>
          {isLoading || showSkeleton ? (
            <Skeleton.Avatar active size={30} />
          ) : (
            <ImageWithFallback
              width={30}
              height={30}
              alt="Instagram"
              fallbackSrc={defaultLink}
              src={data.favicon_url || defaultLink}
            />
          )}
          <LinkContainer>
            {dataServer ? (
              <STitleContainer>
                <Text
                  strong
                  ellipsis={{ tooltip: true }}
                  className={fontVariants.opt2}
                >
                  {data.title}
                </Text>
                <DomainContainer>
                  <Text
                    type="secondary"
                    ellipsis={{ tooltip: true }}
                    className={fontVariants.opt3}
                  >
                    {data.domain}
                  </Text>
                  <Image src={linkIcon} alt="Open in new window" />
                </DomainContainer>
              </STitleContainer>
            ) : (
              <CTitleContainer>
                {isLoading || showSkeleton ? (
                  <TitleSkeleton
                    active
                    loading
                    title={{ width: 100 }}
                    paragraph={{ rows: 1, width: 150 }}
                  />
                ) : (
                  <>
                    <TitleInput uid={dataGrid.i} value={data.title} />
                    <ErrorBoundary fallback={<></>}>
                      <DomainLink target="_blank" href={data.link || '/'}>
                        <Text
                          type="secondary"
                          ellipsis={{ tooltip: true }}
                          className={fontVariants.opt3}
                        >
                          {data.domain}
                        </Text>
                        <Image src={linkIcon} alt="Open in new window" />
                      </DomainLink>
                    </ErrorBoundary>
                  </>
                )}
              </CTitleContainer>
            )}
          </LinkContainer>
        </HeaderDetails>
      </CardHeader>

      <CardFooter
        size={size}
        imageUrl={data?.image_url}
        isLoadingImage={isLoadingImage}
        isLoading={isLoading || isLoadingImage || showSkeleton}
      />
    </CardBody>
  );
};
