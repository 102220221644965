'use client';

import React from 'react';
import Image from 'next/image';
import * as styles from './styles';
import { Box } from '@shared/ui/box';
import { prefixCls } from '@shared/theme';
import { Text } from '@shared/ui/typography';
import { Builder } from '@shared/types/builder';
import { Actions } from '../../../Common/Actions';
import styled, { CSSObject } from '@emotion/styled';
import {
  defaultEndColor,
  defaultStartColor,
  DraggableCancel,
} from '@shared/constants';
import ytIcon from '@shared/icons/youtube-light.svg';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  selectActiveItem,
  selectMediakitTheme,
  useSelector,
} from '@shared/redux';
import { useSkeletonLoader } from '@shared/hooks/useSkeletonLoader';
import { getYouTubeAccountDetails } from '@widgets/mediakit/helpers';
import { builderCardSizes } from '@shared/constants/builderCardSizes';
import { Button, Modal, Progress, Skeleton, Tooltip, Typography } from 'antd';
import { fontVariants } from '@shared/constants/fontVariants';

const StyledProgress = styled(Progress)({
  [`&.${prefixCls}-progress.${prefixCls}-progress-line`]: {
    margin: 0,
  },
});

const ViewMore = ({
  localData,
  startColor,
}: {
  localData: any[];
  startColor: string;
}) => {
  const matches500 = useBreakpoint(500);
  const [showModal, setShowModal] = React.useState(false);

  return (
    <Box
      boxStyles={{
        bottom: 2,
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Button
        type="link"
        className={DraggableCancel}
        onClick={() => setShowModal(true)}
      >
        View More
      </Button>
      <Modal
        closable
        styles={{
          body: {
            maxHeight: 300,
            overflowY: 'auto',
            paddingInline: 24,
            paddingBottom: 20,
          },
          content: {
            padding: 0,
          },
          header: {
            paddingTop: 20,
            paddingInline: 24,
          },
          footer: {
            paddingBottom: 20,
            paddingInline: 24,
          },
        }}
        destroyOnClose
        open={showModal}
        cancelText="Close"
        title="Top Countries"
        rootClassName={DraggableCancel}
        onCancel={() => setShowModal(false)}
        width={!matches500 ? 420 : undefined}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <Box
          boxStyles={{
            gap: 16,
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: 16,
          }}
        >
          {localData.map((item: any, index: number) => {
            const percent = Number((item.weight * 100).toFixed(2));

            return (
              <Box
                key={index}
                boxStyles={{ flexDirection: 'column', maxWidth: '100%' }}
              >
                <Box
                  boxStyles={{
                    gap: '0.8rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Image
                    alt={''}
                    width={20}
                    height={15}
                    src={`/countries/country-${item.code?.toLowerCase()}.svg`}
                  />
                  <Typography.Text>{item.name}</Typography.Text>
                </Box>

                <Box
                  boxStyles={{
                    gap: 16,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <StyledProgress
                    showInfo={false}
                    percent={percent}
                    strokeColor={startColor}
                  />
                  <Box
                    boxStyles={{
                      width: 45,
                      minWidth: 45,
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Typography.Text
                      style={{
                        fontSize: 12,
                        lineHeight: '20px',
                        color: '#00000073',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {percent}%
                    </Typography.Text>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Modal>
    </Box>
  );
};

function Content({
  data,
  dataServer,
  disableSkeleton,
  activeSize: size,
}: {
  dataServer?: boolean;
  disableSkeleton?: boolean;
  activeSize: builderCardSizes;
  data: Builder.GridComponentProps['data'];
}) {
  const showSkeleton = useSkeletonLoader(disableSkeleton);

  const theme = useSelector(selectMediakitTheme);
  let startColor = defaultStartColor;
  if (theme?.primary_color) {
    startColor = theme.primary_color;
  }

  const detailsFunc = getYouTubeAccountDetails()[data.name];
  const { title, tooltip } = detailsFunc({
    size,
  } as any);

  const localData = ([...(data?.data || [])] || []).sort((a: any, b: any) => {
    return b.weight - a.weight;
  });

  let sortedData = localData.slice(0, 4);

  if (size === builderCardSizes.s22) {
    sortedData = localData.slice(0, 2);
  } else if (size === builderCardSizes.s12) {
    sortedData = localData.slice(0, 1);
  }

  let progressWrapperStyles: CSSObject = {
    gap: 16,
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  let listWrapperStyles: CSSObject = { flexDirection: 'column', gap: 20 };

  let nameTextStyles: React.CSSProperties = {};
  if ([builderCardSizes.s14, builderCardSizes.s12].includes(size)) {
    nameTextStyles = {
      fontSize: 12,
      lineHeight: '20px',
      whiteSpace: 'nowrap',
    };
  }

  if (size !== builderCardSizes.s24) {
    progressWrapperStyles = {
      flexDirection: 'column',
      alignItems: 'flex-start',
    };
  }

  if (size === builderCardSizes.s14) {
    listWrapperStyles = {
      gap: 4,
      flexDirection: 'column',
    };
  }

  if (size === builderCardSizes.s22) {
    listWrapperStyles = {
      gap: 16,
      flexDirection: 'row',
    };
  }

  return (
    <Box boxStyles={styles.cardBodyStyles(size)}>
      <Box boxStyles={styles.cardBodyHeaderStyles}>
        <Skeleton
          active
          paragraph={false}
          title={styles.titleTitleProps}
          style={{ display: showSkeleton ? 'table' : 'none' }}
        />
        <Box
          boxStyles={Object.assign({}, styles.cardBodyHeaderDetailsStyles, {
            opacity: showSkeleton ? 0 : 1,
          })}
        >
          <Image src={ytIcon} alt="YouTube" />
          <Text
            className={fontVariants.opt3}
            textProps={styles.cardBodyHeaderDetailsTitleStyles(size)}
          >
            {title}
          </Text>
        </Box>

        {tooltip && (
          <Tooltip title={tooltip}>
            <InfoCircleOutlined
              style={{
                fontSize: '2rem',
                color: '#00000073',
              }}
            />
          </Tooltip>
        )}
      </Box>
      <Box
        boxStyles={Object.assign({}, styles.cardBodyDescriptionStyles, {
          height: '100%',
          display: showSkeleton ? 'flex' : 'none',
          '& .mediakit-skeleton.mediakit-skeleton-element': {
            width: '100%',
            height: '100%',
            '& .mediakit-skeleton-image': {
              width: '100%',
              height: '100%',
            },
          },
        })}
      >
        <Skeleton.Node> </Skeleton.Node>
      </Box>
      <Box
        boxStyles={Object.assign({}, styles.cardBodyDescriptionStyles, {
          flexGrow: 1,
          width: '100%',
          height: '100%',
          position: 'relative',
          flexDirection: 'column',
          display: showSkeleton ? 'none' : 'flex',
        })}
      >
        <Box boxStyles={listWrapperStyles}>
          {sortedData.map((item: any, index: number) => {
            const percent = Number((item.weight * 100).toFixed(2));

            return (
              <Box
                key={index}
                boxStyles={{ flexDirection: 'column', maxWidth: '100%' }}
              >
                <Box
                  boxStyles={{
                    gap: '0.8rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Image
                    alt={''}
                    width={20}
                    height={15}
                    src={`/countries/country-${item.code?.toLowerCase()}.svg`}
                  />
                  <Typography.Text
                    style={nameTextStyles}
                    className={fontVariants.opt3}
                  >
                    {item.name}
                  </Typography.Text>
                </Box>

                <Box boxStyles={progressWrapperStyles}>
                  <StyledProgress
                    strokeColor={startColor}
                    showInfo={false}
                    percent={percent}
                  />
                  <Box
                    boxStyles={{
                      width: 45,
                    }}
                  >
                    <Typography.Text
                      style={{
                        fontSize: 12,
                        lineHeight: '20px',
                        color: '#00000073',
                        whiteSpace: 'nowrap',
                      }}
                      className={fontVariants.opt3}
                    >
                      {percent}%
                    </Typography.Text>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
        <ViewMore startColor={startColor} localData={localData} />
      </Box>
    </Box>
  );
}

export function TopCountries({
  data,
  index,
  dataGrid,
  dataServer,
  disableSkeleton,
}: Builder.GridComponentProps) {
  const activeItem = useSelector(selectActiveItem);

  const activeSize = `${dataGrid.w}${dataGrid.h}` as builderCardSizes;

  return (
    <Box
      key={dataGrid.i}
      data-grid={dataGrid}
      boxStyles={styles.cardStyles}
      className={activeItem === dataGrid.i ? 'active-item' : ''}
    >
      <Content
        data={data}
        dataServer={dataServer}
        activeSize={activeSize}
        disableSkeleton={disableSkeleton}
      />
      <Actions index={index} dataGrid={dataGrid} />
    </Box>
  );
}
