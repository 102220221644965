export enum AppRoutes {
  index = '/',
  base = '/dashboard',
  dashboard = '/dashboard',
  templates = '/templates',

  login = '/login',
  registerStep1 = '/register/step-1',
  registerStep2 = '/register/step-2',
  forgotPassword = '/forgot-password',

  mobileLogin = '/mobile-auth/login',
  mobileRegisterStep1 = '/mobile-auth/register/step-1',
  mobileRegisterStep2 = '/mobile-auth/register/step-2',
  mobileForgotPassword = '/mobile-auth/forgot-password',
}
