import styled from '@emotion/styled';
import { builderCardSizes } from '@shared/constants/builderCardSizes';

export const HeaderDetails = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size',
})(({ size }: { size: builderCardSizes }) => ({
  width: '100%',
  display: 'flex',
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flexDirection: 'column',
  gap: [builderCardSizes.s12, builderCardSizes.s22].includes(size)
    ? '.6rem'
    : '1.4rem',
}));
