import styled from '@emotion/styled';

export const DomainContainer = styled('div')(() => ({
  gap: '.5rem',
  display: 'flex',
  maxWidth: '100%',
  overflow: 'hidden',
  alignItems: 'center',
  textOverflow: 'ellipsis',
}));
