import React from 'react';
import { Input } from 'antd';
import { Box } from '@shared/ui/box';
import { CSSObject } from '@emotion/styled';
import { DraggableCancel } from '@shared/constants';
import { fontVariants } from '@shared/constants/fontVariants';
import {
  useDispatch,
  useSelector,
  onUpdateExtraDataItem,
  selectCurrentMediakitId,
} from '@shared/redux';

const inputStyle: React.CSSProperties = {
  top: 0,
  left: 0,
  width: '100%',
  paddingLeft: 0,
  position: 'absolute',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

const boxStyles: CSSObject = {
  height: 30,
  position: 'relative',
};

export const TitleInput = ({ uid, value }: { value?: string; uid: string }) => {
  const dispatch = useDispatch();
  const mediakitId = useSelector(selectCurrentMediakitId);
  const [localValue, setLocalValue] = React.useState<string>('');

  React.useEffect(() => {
    setLocalValue(value || '');
  }, [value]);

  const handleInputOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.currentTarget.classList.remove(DraggableCancel);

    if (value?.trim() === localValue?.trim()) {
      return;
    }

    localStorage.setItem(`${uid}_${mediakitId}_title`, 'true');

    dispatch(
      onUpdateExtraDataItem({
        i: uid,
        item: {
          value: localValue,
          key: e.target.name as 'title',
        },
      }),
    );
  };

  const handleInputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.currentTarget.classList.add(DraggableCancel);
  };

  const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.currentTarget.value || '');
  };

  const handleOnPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.currentTarget.blur();
  };

  return (
    <Box boxStyles={boxStyles}>
      <Input
        name="title"
        autoComplete="off"
        value={localValue}
        style={inputStyle}
        variant="borderless"
        placeholder="Add a title"
        onBlur={handleInputOnBlur}
        onFocus={handleInputOnFocus}
        onChange={handleInputOnChange}
        onPressEnter={handleOnPressEnter}
        className={`${fontVariants.opt2} ${DraggableCancel}`}
      />
    </Box>
  );
};
