'use client';

import clsx from 'clsx';
import React from 'react';
import Link from 'next/link';
import * as styles from './styles';
import NextImage from 'next/image';
import styled from '@emotion/styled';
import { Box } from '@shared/ui/box';
import { RcFile } from 'antd/es/upload';
import * as Icons from '@shared/ui/Icons';
import { Text } from '@shared/ui/typography';
import linkIcon from '@shared/icons/link.svg';
import { Builder } from '@shared/types/builder';
import fallback from '@shared/icons/fallback.png';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { Layout as ILayout } from 'react-grid-layout';
import { ActionButton } from '@shared/ui/action-button';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import ImageWithFallback from '@shared/ui/image-with-fallback';
import { useUploadImageMutation } from '@shared/services/mediakit';
import { Actions } from '@widgets/mediakit/MediaKit/Common/Actions';
import { builderCardSizes } from '@shared/constants/builderCardSizes';
import { allowedImages, defaultSlug, DraggableCancel } from '@shared/constants';
import {
  useDispatch,
  useSelector,
  selectActiveItem,
  onUpdateExtraDataItem,
  selectUploadImageStates,
} from '@shared/redux';
import {
  Form,
  Input,
  Modal,
  Upload,
  message,
  Divider,
  Skeleton,
  InputRef,
} from 'antd';
import { fontVariants } from '@shared/constants/fontVariants';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';

const StyledUpload = styled(Upload)(() => styles.uploadStyles);

const SkeletonImage = styled(Skeleton.Image)(() => styles.skeletonImageStyles);

function Content({
  data,
  dataGrid,
  dataServer,
  showSkeleton,
}: {
  dataGrid: ILayout;
  dataServer?: boolean;
  showSkeleton?: boolean;
  disableSkeleton?: boolean;
  activeSize: builderCardSizes;
  data: Builder.GridComponentProps['data'];
}) {
  const [quality, setQuality] = React.useState(10);
  const uploadImageStates = useSelector(selectUploadImageStates);

  const isLoading = uploadImageStates[dataGrid.i] || showSkeleton;

  React.useEffect(() => {
    if (isLoading) {
      setQuality(10);
    }
  }, [isLoading]);

  let hostname;

  if (data.link) {
    try {
      let newLink = data.link;
      if (!newLink.startsWith('http')) {
        newLink = `https://${data.link}`;
      }
      const newUrl = new URL(newLink);

      hostname = newUrl.hostname;

      if (hostname.startsWith('www.')) {
        hostname = hostname.replace('www.', '');
      }
    } catch (e) {
      console.error(e);

      hostname = data.link;
    }
  }

  return (
    <Box boxStyles={styles.cardBodyStyles}>
      <Box
        boxStyles={{
          top: 0,
          left: 0,
          zIndex: 1,
          width: '100%',
          height: '100%',
          userSelect: 'none',
          position: 'absolute',
          backgroundColor: 'transparent',
        }}
      />
      {!!data.link && (
        <Box boxStyles={styles.hostnameContainerStyles}>
          {isLoading && (
            <Box
              boxStyles={{
                left: 0,
                top: 0,
                zIndex: 1,
                width: '100%',
                height: '100%',
                position: 'absolute',
                backgroundColor: 'white',
              }}
            />
          )}
          <Text
            ellipsis
            className={fontVariants.opt3}
            style={styles.hostnameStyles}
          >
            {dataServer ? (
              hostname
            ) : (
              <Link
                target="_blank"
                href={data.link}
                className={fontVariants.opt3}
              >
                {hostname}
              </Link>
            )}
          </Text>
          <NextImage src={linkIcon} alt="Open in new window" />
        </Box>
      )}
      {data?.image_url ? (
        isLoading ? (
          <>
            {!data.link && <Box boxStyles={styles.linkSkeletonStyles} />}
            <SkeletonImage active />
          </>
        ) : (
          <ImageWithFallback
            priority
            fill={true}
            quality={quality}
            src={data.image_url}
            sizes={styles.sizes}
            alt={data.link || ''}
            fallbackSrc={fallback}
            style={styles.imageStyles}
            onLoad={() => setQuality(90)}
          />
        )
      ) : (
        <>
          <Box boxStyles={styles.linkSkeletonStyles} />
          <SkeletonImage active />
        </>
      )}
    </Box>
  );
}

export function Image({
  data,
  index,
  dataGrid,
  dataServer,
  showSkeleton,
  disableSkeleton,
}: Builder.GridComponentProps) {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const matches = useBreakpoint(400);
  const [upload, res] = useUploadImageMutation();
  const inputRef = React.useRef<InputRef>(null);
  const activeItem = useSelector(selectActiveItem);

  React.useEffect(() => {
    if (res.status === 'fulfilled' && posthog) {
      try {
        posthog?.capture(PosthogEvents.ImageChange);
      } catch (e) {
        console.error('Posthog error:', e);
      }
    }
  }, [res.status, posthog]);

  const [value, handleOnChange] = React.useState(data.link);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const activeSize = `${dataGrid.w}${dataGrid.h}` as builderCardSizes;

  React.useEffect(() => {
    handleOnChange(data?.link || '');
  }, [data?.link]);

  const handleOk = () => {
    setIsModalOpen(false);

    if (value?.trim() || !!data?.link) {
      let newValue = value?.trim();
      if (newValue && !newValue?.startsWith('http')) {
        newValue = `https://${newValue}`;
      }
      dispatch(
        onUpdateExtraDataItem({
          i: dataGrid.i,
          item: {
            key: 'link',
            value: newValue || '',
          },
        }),
      );

      if (newValue && newValue !== data?.link) {
        try {
          posthog?.capture(PosthogEvents.ImageAddLink);
        } catch (e) {
          console.error('Posthog error:', e);
        }
      }
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const beforeUpload = (file: RcFile) => {
    const isAllowedFormat = allowedImages.includes(file.type);

    if (!isAllowedFormat) {
      message.error('You can only upload jpg/jpeg/png/webp file!').then();

      return false;
    }

    const isAllowedSize = file.size / 1024 / 1024 < 20;

    if (!isAllowedSize) {
      message.error('Image must smaller than 20MB!').then();

      return false;
    }

    return true;
  };

  const setAutoFocus = (open: boolean) => {
    if (open) {
      inputRef.current?.focus();
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);

    handleOnChange(data?.link || '');
  };

  const customRequest = async ({ file }: UploadRequestOption) => {
    const formData = new FormData();

    formData.append('image', file);
    formData.append('uid', dataGrid.i);

    upload({
      data: formData,
      slug: defaultSlug,
    });
  };

  return (
    <Box
      key={dataGrid.i}
      data-grid={dataGrid}
      boxStyles={styles.cardStyles}
      className={clsx(
        'image-component',
        activeItem === dataGrid.i && 'active-item',
      )}
    >
      <Modal
        okText="Save"
        onOk={handleOk}
        title="Add a Link"
        open={isModalOpen}
        maskClosable={false}
        onCancel={handleCancel}
        className={DraggableCancel}
        afterOpenChange={setAutoFocus}
        rootClassName={DraggableCancel}
        width={matches ? undefined : 360}
        okButtonProps={{ disabled: !value?.trim() && !data?.link }}
      >
        <Form size="large" layout="vertical" component="div">
          <Form.Item label="Link">
            <Input
              type="url"
              name="link"
              value={value}
              ref={inputRef}
              autoComplete="off"
              placeholder="www.example.com"
              onChange={(e) => handleOnChange(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Content
        data={data}
        dataGrid={dataGrid}
        activeSize={activeSize}
        dataServer={dataServer}
        showSkeleton={showSkeleton}
        disableSkeleton={disableSkeleton}
      />
      <Actions
        index={index}
        dataGrid={dataGrid}
        extraActions={
          <>
            <Divider type="vertical" style={styles.dividerStyles} />
            <StyledUpload
              name="image"
              method="POST"
              showUploadList={false}
              beforeUpload={beforeUpload}
              customRequest={customRequest}
              accept={allowedImages.join(',')}
            >
              <ActionButton>
                <Icons.ReplaceIcon widthHelper />
              </ActionButton>
            </StyledUpload>
            <ActionButton onClick={showModal}>
              <Icons.LinkIcon widthHelper />
            </ActionButton>
          </>
        }
      />
    </Box>
  );
}
