import styled from '@emotion/styled';

export const CTitleContainer = styled('div')(() => ({
  gap: '.8rem',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export const STitleContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));
