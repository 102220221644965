import { Divider } from 'antd';
import styled from '@emotion/styled';

export const VerticalDivider = styled(Divider)(() => ({
  height: '2rem',
  marginTop: 'auto',
  position: 'static',
  marginBottom: 'auto',
  backgroundColor: '#FFFFFF80',
}));
