import React from 'react';
import { RcFile } from 'antd/es/upload';
import { StyledUpload } from './styled-upload';
import { Builder } from '@shared/types/builder';
import { VerticalDivider } from './vertical-divider';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { ActionButton } from '@shared/ui/action-button';
import { LinkIcon, ReplaceIcon } from '@shared/ui/Icons';
import { Form, Input, InputRef, message, Modal } from 'antd';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { allowedImages, defaultSlug, DraggableCancel } from '@shared/constants';
import {
  useDispatch,
  useSelector,
  onUpdateExtraDataItem,
  selectCurrentMediakitId,
} from '@shared/redux';
import {
  useLinkCrawlMutation,
  useUploadImageMutation,
} from '@shared/services/mediakit';

export const ExtraActions: React.FC<{
  data: Builder.GridComponentProps['data'];
  dataGrid: Builder.GridComponentProps['dataGrid'];
}> = ({ data, dataGrid }) => {
  const dispatch = useDispatch();
  const matches = useBreakpoint(400);
  const mediakitId = useSelector(selectCurrentMediakitId);

  const [craw] = useLinkCrawlMutation();
  const [upload] = useUploadImageMutation();

  const inputRef = React.useRef<InputRef>(null);

  const [value, handleOnChange] = React.useState('');

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  React.useEffect(() => {
    handleOnChange(data?.link || '');
  }, [data?.link]);

  const handleOk = () => {
    setIsModalOpen(false);

    if (value?.trim() === data?.link?.trim()) {
      return;
    }

    if (value?.trim()) {
      dispatch(
        onUpdateExtraDataItem({
          i: dataGrid.i,
          item: {
            key: 'value',
            value: value?.trim(),
          },
        }),
      );

      craw({
        uid: dataGrid.i,
        slug: defaultSlug,
        link: value?.trim(),
      });
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);

    if (data?.link) {
      handleOnChange(data?.link);
    }
  };

  const showModal = () => setIsModalOpen(true);

  const beforeUpload = (file: RcFile) => {
    const isAllowedFormat = allowedImages.includes(file.type);

    if (!isAllowedFormat) {
      message.error('You can only upload jpg/jpeg/png/webp file!').then();

      return false;
    }

    const isAllowedSize = file.size / 1024 / 1024 < 20;

    if (!isAllowedSize) {
      message.error('Image must smaller than 20MB!').then();

      return false;
    }

    return true;
  };

  const setAutoFocus = (open: boolean) => {
    if (open) {
      inputRef.current?.focus();
    }
  };

  const customRequest = async ({ file }: UploadRequestOption) => {
    const formData = new FormData();

    formData.append('image', file);
    formData.append('uid', dataGrid.i);

    const res = await upload({
      data: formData,
      slug: defaultSlug,
    });

    if ('data' in res) {
      if (res?.data?.data?.image) {
        localStorage.setItem(`${dataGrid.i}_${mediakitId}_image`, 'true');
      }
    }
  };

  return (
    <>
      <Modal
        okText="Save"
        onOk={handleOk}
        title="Add a Link"
        open={isModalOpen}
        maskClosable={false}
        onCancel={handleCancel}
        className={DraggableCancel}
        afterOpenChange={setAutoFocus}
        rootClassName={DraggableCancel}
        width={matches ? undefined : 360}
        okButtonProps={{ disabled: !value?.trim() }}
      >
        <Form size="large" layout="vertical" component="div">
          <Form.Item label="Link">
            <Input
              type="url"
              name="link"
              value={value}
              ref={inputRef}
              autoComplete="off"
              placeholder="www.example.com"
              onChange={(e) => handleOnChange(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>

      <VerticalDivider type="vertical" />

      <StyledUpload
        name="image"
        method="POST"
        showUploadList={false}
        beforeUpload={beforeUpload}
        customRequest={customRequest}
        accept={allowedImages.join(',')}
      >
        <ActionButton>
          <ReplaceIcon widthHelper />
        </ActionButton>
      </StyledUpload>
      <ActionButton onClick={showModal}>
        <LinkIcon widthHelper />
      </ActionButton>
    </>
  );
};
