import React from 'react';
import { Resize12 } from './resize12';
import { Resize22 } from './resize22';
import { Resize14 } from './resize14';
import { Resize24 } from './resize24';
import Cookies from 'universal-cookie';
import * as Icons from '@shared/ui/Icons';
import { DoneAction } from './done-action';
import { LinkIcon } from '@shared/ui/Icons';
import Dropzone, { DropzoneFile } from 'dropzone';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { ActionsDivider } from '../ui/actions-divider';
import type { ImageBottomActionsProps } from '../types';
import { ActionButton } from '@shared/ui/action-button';
import { App, Flex, Form, Input, InputRef, Modal } from 'antd';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { allowedVideos, DraggableCancel } from '@shared/constants';
import { builderCardSizes } from '@shared/constants/builderCardSizes';
import {
  useDispatch,
  useSelector,
  selectExtraData,
  selectActiveItem,
  selectCurrentLayout,
  onUpdateExtraDataItem,
  videoUploadMatchPending,
  videoUploadMatchFulfilled,
} from '@shared/redux';

interface ReactDropzoneProps {
  id: string;
  onAddedfile: (file: DropzoneFile) => void;
  onSuccess: (file: DropzoneFile, response: Object | string) => void;
  onSending: (
    file: DropzoneFile,
    xhr: XMLHttpRequest,
    formData: FormData,
  ) => void;
}

export const ReactDropzone: React.FC<
  React.PropsWithChildren<ReactDropzoneProps>
> = ({ id, children, onSuccess, onSending, onAddedfile }) => {
  const { message } = App.useApp();
  const posthog = useLocalPostHog();
  const dropzoneRef = React.useRef<Dropzone | null>(null);

  const addedfile = React.useCallback(
    (file: Dropzone.DropzoneFile) => {
      const isAllowedFormat = allowedVideos.includes(file.type);

      if (!isAllowedFormat) {
        message.error('You can only upload mp4 file!').then(console.log);

        return false;
      }

      const isAllowedSize = file.size / 1024 / 1024 < 1024;

      if (!isAllowedSize) {
        message.error('Video must smaller than 1GB!').then();

        try {
          posthog?.capture(PosthogEvents.MoreThanAllowedSize, {
            type: 'video',
            size: file.size,
          });
        } catch (e) {
          console.error('Posthog error:', e);
        }

        return false;
      }

      onAddedfile(file);

      dropzoneRef.current?.uploadFile(file);
    },
    [message, onAddedfile, posthog],
  );

  React.useEffect(() => {
    if (dropzoneRef.current || !id) {
      return;
    }

    const cookies = new Cookies();
    const access_token = cookies.get('access_token') ?? null;

    dropzoneRef.current = new Dropzone(`#${id}`, {
      maxFiles: 1,
      chunking: true,
      autoQueue: false,
      paramName: 'file',
      maxFilesize: 1024,
      uploadMultiple: false,
      autoProcessQueue: false,
      previewsContainer: false,
      chunkSize: 1024 * 1024 * 2,
      acceptedFiles: allowedVideos.join(','),
      url: '/api/mediakit-builder/upload-video',
      headers: { Authorization: `Bearer ${access_token}` },
    });

    dropzoneRef.current
      .on('sending', onSending)
      .on('success', onSuccess)
      .on('addedfile', addedfile);
  }, [id, message, addedfile, onSuccess, onSending, onAddedfile]);

  return (
    <form className="dropzone" id={id}>
      <input
        type="file"
        name="file"
        maxLength={1}
        style={{ display: 'none' }}
        accept={allowedVideos.join(',')}
      />
      <div className="dz-default dz-message">
        <div className="dz-button">{children}</div>
      </div>
    </form>
  );
};

export const VideoBottomActions: React.FC<ImageBottomActionsProps> = ({
  activeSize,
}) => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const matches = useBreakpoint(400);
  const inputRef = React.useRef<InputRef>(null);
  const layout = useSelector(selectCurrentLayout);
  const activeItem = useSelector(selectActiveItem);
  const [value, handleOnChange] = React.useState('');
  const extraData = useSelector(selectExtraData) as any;
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const link = extraData[activeItem as any]?.link;

  React.useEffect(() => {
    handleOnChange(link || '');
  }, [link]);

  React.useEffect(() => {
    return function () {
      handleOnChange('');
    };
  }, []);

  React.useEffect(() => {
    try {
      posthog?.capture(PosthogEvents.VideoChange);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  }, [posthog]);

  const handleOk = () => {
    setIsModalOpen(false);

    if (value?.trim() || !!link) {
      let newValue = value?.trim();
      if (newValue && !newValue?.startsWith('http')) {
        newValue = `https://${newValue}`;
      }

      dispatch(
        onUpdateExtraDataItem({
          i: dataGrid.i,
          item: {
            key: 'link',
            value: newValue,
          },
        }),
      );

      if (newValue && newValue !== link) {
        try {
          posthog?.capture(PosthogEvents.ImageAddLink);
        } catch (e) {
          console.error('Posthog error:', e);
        }
      }
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);

    handleOnChange(link || '');
  };

  const setAutoFocus = (open: boolean) => {
    if (open) {
      inputRef.current?.focus();
    }
  };

  const activeIndex = layout.findIndex((e) => e.i === activeItem);

  const dataGrid = layout[activeIndex];

  const onSending = React.useCallback(
    (file: Dropzone.DropzoneFile, _xhr: XMLHttpRequest, formData: FormData) => {
      formData.append('uid', dataGrid.i);

      dispatch(
        videoUploadMatchPending({
          uid: dataGrid.i,
          data: {
            totalSize: file.size,
            bytesSent: file.upload?.bytesSent,
          },
        }),
      );
    },

    [dataGrid.i, dispatch],
  );

  const onSuccess = React.useCallback(
    (file: Dropzone.DropzoneFile, response: Object | string) => {
      if (typeof response !== 'string') {
        dispatch(
          videoUploadMatchFulfilled({
            uid: (response as any).uid,
            url: (response as any).url,
            thumb_url: (response as any).thumb_url,
          }),
        );
      }

      posthog?.capture(PosthogEvents.VideoChange);
    },
    [dispatch, posthog],
  );

  const onAddedfile = React.useCallback(
    (_file: Dropzone.DropzoneFile) => {
      dispatch(videoUploadMatchPending({ uid: dataGrid.i }));
    },
    [dispatch, dataGrid.i],
  );

  return (
    <>
      <Flex gap=".6rem" align="center">
        <Modal
          okText="Save"
          onOk={handleOk}
          title="Add a Link"
          open={isModalOpen}
          maskClosable={false}
          onCancel={handleCancel}
          className={DraggableCancel}
          afterOpenChange={setAutoFocus}
          rootClassName={DraggableCancel}
          width={matches ? undefined : 360}
          okButtonProps={{ disabled: !value?.trim() && !link }}
        >
          <Form size="large" layout="vertical" component="div">
            <Form.Item label="Link">
              <Input
                type="url"
                name="link"
                value={value}
                ref={inputRef}
                autoComplete="off"
                placeholder="www.example.com"
                onChange={(e) => handleOnChange(e.target.value)}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Resize12
          index={activeIndex}
          isActive={activeSize === builderCardSizes.s12}
        />
        <Resize22
          index={activeIndex}
          isActive={activeSize === builderCardSizes.s22}
        />
        <Resize14
          index={activeIndex}
          isActive={activeSize === builderCardSizes.s14}
        />
        <Resize24
          index={activeIndex}
          isActive={activeSize === builderCardSizes.s24}
        />
        <>
          <ActionsDivider type="vertical" />

          <ReactDropzone
            onSuccess={onSuccess}
            onSending={onSending}
            onAddedfile={onAddedfile}
            id={dataGrid.i?.replace('_+_', 'bottom')}
          >
            <ActionButton type="button">
              <Icons.ReplaceIcon widthHelper />
            </ActionButton>
          </ReactDropzone>

          <ActionButton tabIndex={-1} onClick={showModal}>
            <LinkIcon widthHelper />
          </ActionButton>
        </>
      </Flex>
      <DoneAction />
    </>
  );
};
