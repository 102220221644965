'use client';

import clsx from 'clsx';
import React from 'react';
import { v4 as uuidV4 } from 'uuid';
import { Box } from '@shared/ui/box';
import { ContactForm } from './contact-form';
import { Builder } from '@shared/types/builder';
import { hexToRgb } from '@shared/utils/hexToRgb';
import { ActionButton } from '@shared/ui/action-button';
import { fontVariants } from '@shared/constants/fontVariants';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { formatWithCommas } from '@shared/utils/formatWithCommas';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { CTAButton, EditIcon, StyledDivider } from './components';
import { Divider, Flex, Modal, Skeleton, Typography } from 'antd';
import { Actions } from '@widgets/mediakit/MediaKit/Common/Actions';
import { defaultEndColor, DraggableCancel } from '@shared/constants';
import { textContrastChecker } from '@shared/utils/textContrastChecker';
import { CardTitle, CardTitleSkeleton, Card, CardBody } from './card';
import { ListItem, ListItemTitle, ListItemTitleSkeleton } from './list';
import {
  useDispatch,
  useSelector,
  selectActiveItem,
  onUpdateExtraDataItem,
  onUpdateReactGridItem,
  onUpdateExtraDataServiceListItem,
} from '@shared/redux';
import {
  PlusOutlined,
  InfoCircleFilled,
  ArrowRightOutlined,
} from '@ant-design/icons';
import {
  largeSize,
  builderCardSizes,
} from '@shared/constants/builderCardSizes';
import {
  currency,
  CRUDServiceList,
} from '@widgets/mediakit/MediaKit/Common/CRUDServiceList';

const keys = [
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
];

export function ServiceList({
  data,
  index,
  theme,
  handle,
  dataGrid,
  dataServer,
  showSkeleton,
}: Omit<Builder.GridComponentProps, 'data'> & { data: any }) {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [initialData, setInitialData] = React.useState<any>(undefined);
  const [isWarningModalIsOpen, setWarningModalIsOpen] = React.useState(false);
  const [isContactModalIsOpen, setContactModalIsOpen] = React.useState<
    number | null
  >(null);

  const activeItem = useSelector(selectActiveItem);
  const activeSize = `${dataGrid.w}${dataGrid.h}` as builderCardSizes;

  const primaryColor = theme?.primary_color || defaultEndColor;

  const { r, g, b } = hexToRgb(primaryColor);
  const buttonTextColor = textContrastChecker(r, g, b);

  const addServiceItem = (data: any) => {
    setIsModalOpen(false);

    let value: any = {
      title: data.title,
    };

    if (data.rateIsEnabled) {
      value = {
        title: data.title,
        rate: Number(data.rate),
        currency: data.localCurrency.value || data.localCurrency,
      };
    }

    dispatch(
      onUpdateExtraDataItem({
        i: dataGrid.i,
        item: {
          value,
          key: 'value',
        },
      }),
    );

    try {
      posthog?.capture(PosthogEvents.ServiceListAddRates);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  const onRemoveItem = (index: number) => {
    const newData = data.filter((_item: any, i: number) => i !== index);
    dispatch(
      onUpdateExtraDataServiceListItem({
        i: dataGrid.i,
        item: {
          value: newData,
          key: 'value',
        },
      }),
    );
  };

  const updateServiceItem = (updatedData: any) => {
    let value: any = {
      title: updatedData.title,
    };

    if (updatedData.rateIsEnabled) {
      value = {
        title: updatedData.title,
        rate: Number(updatedData.rate),
        currency: updatedData.localCurrency.value || updatedData.localCurrency,
      };
    }
    const newData = data.map((item: any, i: number) => {
      if (i === updatedData.index) {
        return { ...value };
      }
      return { ...item };
    });

    dispatch(
      onUpdateExtraDataServiceListItem({
        i: dataGrid.i,
        item: {
          value: newData,
          key: 'value',
        },
      }),
    );
  };

  let dataToUse = [];

  const isLarge = dataGrid.h === largeSize.h;

  if (Array.isArray(data)) {
    dataToUse = data.slice(0, isLarge ? 5 : 2);
  }

  const className = clsx(activeItem === dataGrid.i && 'active-item');

  const onClickAddButton = () => {
    if (activeSize === builderCardSizes.s22 && data.length >= 2) {
      setWarningModalIsOpen(true);
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <Card key={dataGrid.i} data-grid={dataGrid} className={className}>
        <CardTitle>
          <CardTitleSkeleton
            paragraph={false}
            active={showSkeleton}
            loading={showSkeleton}
          >
            <Typography.Text className={fontVariants.opt3}>
              Service Rates
            </Typography.Text>
          </CardTitleSkeleton>
        </CardTitle>

        <CardBody>
          {dataToUse.map((info: any, index: number) => {
            let btnText = 'Ask Me';

            if (info.currency && info.rate) {
              btnText = `${
                (currency as any)[info.currency].label || currency.dollar.label
              }${formatWithCommas(info.rate)} `;
            }

            return (
              <Flex key={keys[index]} vertical>
                <ListItem>
                  {showSkeleton ? (
                    <ListItemTitleSkeleton
                      active
                      loading
                      index={index}
                      paragraph={false}
                      isLarge={isLarge}
                    />
                  ) : (
                    <ListItemTitle isLarge={isLarge}>
                      <Typography.Title
                        level={4}
                        className={fontVariants.opt2}
                        ellipsis={{ tooltip: dataServer }}
                      >
                        {info.title}{' '}
                      </Typography.Title>

                      {!dataServer && (
                        <EditIcon
                          className={DraggableCancel}
                          primaryColor={primaryColor}
                          onClick={() => {
                            setInitialData({ ...info, index });

                            try {
                              posthog?.capture(PosthogEvents.ServiceListEdit);
                            } catch (e) {
                              console.error('Posthog error:', e);
                            }
                          }}
                        />
                      )}
                    </ListItemTitle>
                  )}

                  {showSkeleton ? (
                    <Skeleton.Button active />
                  ) : (
                    <CTAButton
                      type="primary"
                      color={buttonTextColor as any}
                      className={fontVariants.opt2}
                      backgroundColor={primaryColor}
                      onClick={() => {
                        if (dataServer) {
                          setContactModalIsOpen(index);
                        }
                      }}
                    >
                      {btnText} <ArrowRightOutlined />
                    </CTAButton>
                  )}
                </ListItem>

                {index !== dataToUse.length - 1 && (
                  <StyledDivider dataServer={dataServer} isLarge={isLarge} />
                )}
              </Flex>
            );
          })}
        </CardBody>

        <Actions
          index={index}
          dataGrid={dataGrid}
          allowedSizes={[builderCardSizes.s22, builderCardSizes.s24]}
          extraActions={
            <>
              {data?.length < 5 && (
                <>
                  <Divider
                    type="vertical"
                    style={{
                      height: '2rem',
                      marginTop: 'auto',
                      position: 'static',
                      marginBottom: 'auto',
                      backgroundColor: '#FFFFFF80',
                    }}
                  />
                  <ActionButton onClick={onClickAddButton}>
                    <PlusOutlined style={{ color: 'white', fontSize: 16 }} />
                  </ActionButton>
                </>
              )}
            </>
          }
        />

        {isModalOpen && (
          <CRUDServiceList
            data={dataGrid}
            isModalOpen={isModalOpen}
            addServiceItem={addServiceItem}
            setIsModalOpen={setIsModalOpen}
          />
        )}
        {initialData && (
          <CRUDServiceList
            data={dataGrid}
            isModalOpen={true}
            dataLength={data.length}
            initialData={initialData}
            onRemoveItem={onRemoveItem}
            setIsModalOpen={setIsModalOpen}
            setInitialData={setInitialData}
            addServiceItem={updateServiceItem}
          />
        )}
      </Card>

      {dataServer && (
        <Modal
          width={380}
          footer={null}
          destroyOnClose
          style={{ top: 20 }}
          title="Contact Form"
          maskClosable={false}
          open={isContactModalIsOpen !== null}
          onCancel={() => setContactModalIsOpen(null)}
        >
          <Box boxStyles={{ height: 24 }} />
          {isContactModalIsOpen !== null && (
            <ContactForm
              handle={handle}
              serviceList={data}
              index={isContactModalIsOpen}
              toggleModal={setContactModalIsOpen}
            />
          )}
        </Modal>
      )}
      <Modal
        width={400}
        title={null}
        closeIcon={null}
        className={DraggableCancel}
        open={isWarningModalIsOpen}
        onCancel={() => setWarningModalIsOpen(false)}
        onOk={() => {
          setWarningModalIsOpen(false);
          setIsModalOpen(true);

          const [w, h] = builderCardSizes.s24.split('');

          dispatch(onUpdateReactGridItem({ i: index, item: { w: +w, h: +h } }));
        }}
      >
        <Box boxStyles={{ alignItems: 'flex-start', gap: 16 }}>
          <InfoCircleFilled style={{ fontSize: 21, color: '#5956E9' }} />
          <div>
            The service rate card size will be increased
            <br /> to fit more services
          </div>
        </Box>
      </Modal>
    </>
  );
}
