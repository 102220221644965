import styled from '@emotion/styled';
import { builderCardSizes } from '@shared/constants/builderCardSizes';

export const CardBody = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size',
})(({ size }: { size: builderCardSizes }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  gap: size === builderCardSizes.s14 ? '12rem' : '3.8rem',
  flexDirection: size === builderCardSizes.s22 ? 'row' : 'column',
  '& > div': {
    width: size === builderCardSizes.s22 ? '50%' : '100%',
  },
}));
