'use client';

import clsx from 'clsx';
import React from 'react';
import * as styles from './styles';
import { Box } from '@shared/ui/box';
import { prefixCls } from '@shared/theme';
import { Builder } from '@shared/types/builder';
import { hexToRgb } from '@shared/utils/hexToRgb';
import { CheckOutlined } from '@ant-design/icons';
import { DraggableCancel } from '@shared/constants';
import { ActionButton } from '@shared/ui/action-button';
import { Divider, Input, Popover, Skeleton, Typography } from 'antd';
import { Actions } from '@widgets/mediakit/MediaKit/Common/Actions';
import { textContrastChecker } from '@shared/utils/textContrastChecker';
import {
  useDispatch,
  useSelector,
  selectActiveItem,
  onUpdateExtraDataItem,
} from '@shared/redux';
import {
  ColorOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  AlignCenterOutlined,
} from '@shared/ui/Icons';
import { fontVariants } from '@shared/constants/fontVariants';
import styled from '@emotion/styled';

function InputValue({
  uid,
  value,
  align,
  color,
}: {
  uid: string;
  value?: string;
  color?: string;
  align?: string;
}) {
  const dispatch = useDispatch();

  const [localValue, setLocalValue] = React.useState('');

  React.useEffect(() => {
    setLocalValue(value || '');
  }, [value]);

  const handleInputOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.currentTarget.classList.remove(DraggableCancel);

    dispatch(
      onUpdateExtraDataItem({
        i: uid,
        item: {
          key: e.target.name as 'value',
          value: localValue?.trim()?.slice(0, 50),
        },
      }),
    );
  };

  const handleInputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.currentTarget.classList.add(DraggableCancel);
  };

  const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.currentTarget.value || '');
  };

  const handleOnPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.currentTarget.blur();
  };

  return (
    <Input
      name="value"
      autoComplete="off"
      value={localValue}
      variant="borderless"
      style={{
        color,
        fontSize: 30,
        fontWeight: 600,
        lineHeight: '38px',
        backgroundColor: 'transparent',
        textAlign: (align as React.CSSProperties['textAlign']) || 'left',
      }}
      onBlur={handleInputOnBlur}
      placeholder="Add a title..."
      onFocus={handleInputOnFocus}
      className={fontVariants.opt2}
      onChange={handleInputOnChange}
      onPressEnter={handleOnPressEnter}
    />
  );
}

const colors = [
  '#F5222D',
  '#FA8C16',
  '#FAAD14',
  '#FADB14',
  '#A0D911',
  '#52C41A',
  '#13C2C2',
  '#1677FF',
  '#2F54EB',
  '#722ED1',
  '#EB2F96',
  '#825A2C',
  '#FFDD9D',
  '#FFFFFF',
  '#8E8E93',
  '#000000',
];

export const TitleExtraActions = ({
  uuid,
  color: selectedColor,
}: {
  uuid: string;
  color: string;
}) => {
  const dispatch = useDispatch();
  const [colorIsActive, setColorIsActive] = React.useState(false);
  const [inputValue, setInputValue] = React.useState<string>(
    selectedColor?.replace('#', ''),
  );

  const changeColor = (color: string) => () => {
    dispatch(
      onUpdateExtraDataItem({
        i: uuid,
        item: {
          value: color,
          key: 'bg_color',
        },
      }),
    );
  };

  return (
    <>
      <Divider
        type="vertical"
        style={{
          height: '2rem',
          marginTop: 'auto',
          position: 'static',
          marginBottom: 'auto',
          backgroundColor: '#FFFFFF80',
        }}
      />
      <Popover
        trigger="click"
        className={DraggableCancel}
        rootClassName={DraggableCancel}
        onOpenChange={(visible) => setColorIsActive(visible)}
        content={
          <>
            <style>
              {`.${prefixCls}-popover .${prefixCls}-popover-arrow::before { background: #001529 }`}
              {`.${prefixCls}-popover .${prefixCls}-popover-inner .${prefixCls}-popover-inner-content { display: flex }`}
              {`.${prefixCls}-popover .${prefixCls}-popover-inner { background-color: #001529; padding:.5rem; border-radius: 1rem }`}
            </style>
            <Box
              boxStyles={{
                gap: 8,
                padding: '1rem',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                boxStyles={{
                  gap: 8,
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr 1fr',
                }}
              >
                {colors.map((color) => {
                  const { r, g, b } = hexToRgb(color);
                  const textContrast = textContrastChecker(r, g, b);

                  return (
                    <ActionButton
                      key={color}
                      onClick={() => {
                        changeColor(color)();

                        setInputValue(color.replace('#', ''));
                      }}
                    >
                      <Box
                        boxStyles={{
                          position: 'relative',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="11" cy="11" r="10" fill={color} />
                          <circle
                            cx="11"
                            cy="11"
                            r="10.5"
                            stroke="white"
                            strokeOpacity={0.25}
                          />
                        </svg>
                        {color === selectedColor && (
                          <CheckOutlined
                            style={{
                              fontSize: 14,
                              position: 'absolute',
                              color: textContrast,
                            }}
                          />
                        )}
                      </Box>
                    </ActionButton>
                  );
                })}
              </Box>

              <Box
                boxStyles={{
                  [`& .${prefixCls}-input`]: {
                    border: 'none',
                    paddingLeft: 2,
                    color: '#959595',
                    backgroundColor: '#51525A',
                    textTransform: 'uppercase',
                  },
                  [`& .${prefixCls}-input-group-addon`]: {
                    border: 'none',
                    paddingRight: 0,
                    color: '#959595',
                    backgroundColor: '#51525A',
                  },
                }}
              >
                <Input
                  maxLength={6}
                  addonBefore="#"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value?.trim())}
                  onBlur={() => {
                    if (inputValue.length === 6) {
                      changeColor(`#${inputValue}`)();
                    }
                  }}
                />
              </Box>
            </Box>
          </>
        }
      >
        <ActionButton>
          <ColorOutlined isActive={colorIsActive} />
        </ActionButton>
      </Popover>
    </>
  );
};

export function Title({
  data,
  index,
  dataGrid,
  dataServer,
  showSkeleton,
}: Builder.GridComponentProps) {
  const dispatch = useDispatch();
  const activeItem = useSelector(selectActiveItem);

  const changeAlignment = (alignment: string) => () => {
    dispatch(
      onUpdateExtraDataItem({
        i: dataGrid.i,
        item: {
          key: 'align',
          value: alignment,
        },
      }),
    );
  };

  return (
    <Box
      key={dataGrid.i}
      data-grid={dataGrid}
      className={clsx(activeItem === dataGrid.i && 'active-item')}
      boxStyles={Object.assign({}, styles.cardStyles, {
        alignItems: 'center',
      })}
    >
      {dataServer ? (
        <Typography.Title
          level={2}
          className="fonts-opt2"
          style={{
            width: '100%',
            marginBottom: 0,
            paddingInline: 11,
            color: data.bg_color || '#000000',
            textAlign:
              (data.align as React.CSSProperties['textAlign']) || 'left',
          }}
          ellipsis={{ tooltip: true }}
        >
          {data?.value || ''}
        </Typography.Title>
      ) : showSkeleton ? (
        <Skeleton.Input block active></Skeleton.Input>
      ) : (
        <InputValue
          uid={dataGrid.i}
          align={data.align}
          value={data?.value}
          color={data.bg_color || '#000000'}
        />
      )}
      <Actions
        index={index}
        allowedSizes={[]}
        dataGrid={dataGrid}
        extraActions={
          <>
            <ActionButton onClick={changeAlignment('left')}>
              <AlignLeftOutlined
                isActive={data.align === 'left' || !data.align}
              />
            </ActionButton>
            <ActionButton onClick={changeAlignment('center')}>
              <AlignCenterOutlined isActive={data.align === 'center'} />
            </ActionButton>
            <ActionButton onClick={changeAlignment('right')}>
              <AlignRightOutlined isActive={data.align === 'right'} />
            </ActionButton>

            <TitleExtraActions
              uuid={dataGrid.i}
              color={data.bg_color || '#000000'}
            />
          </>
        }
      />
    </Box>
  );
}
