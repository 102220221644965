'use client';

import React from 'react';
import { Builder } from '@shared/types/builder';
import { Layout as ILayout } from 'react-grid-layout';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { ResponsiveGridLayout } from '@shared/ui/GridLayout';
import { selectPreviewMode, useSelector } from '@shared/redux';
import { GridContainer } from '@widgets/mediakit/grid-layout/styles';
import { LayoutBreakpoints } from '@shared/constants/layoutBreakpoints';
import {
  cols,
  generateDOM,
  gridBreakpoints,
} from '@widgets/mediakit/grid-layout/helpers';

export function GridLayoutServer({
  theme,
  handle,
  layouts,
  extraData,
  isPreview,
}: {
  theme: any;
  layouts: {
    [LayoutBreakpoints.sm]: ILayout[];
    [LayoutBreakpoints.xxs]: ILayout[];
  };
  handle: string;
  isPreview?: boolean;
  extraData: Builder.ExtraData;
}) {
  const matches = useBreakpoint(991);
  const previewMode = useSelector(selectPreviewMode);
  const [breakpoint, setBreakpoint] = React.useState<LayoutBreakpoints>(
    LayoutBreakpoints.sm,
  );
  const [rowHeight, setRowHeight] = React.useState<number>(73);
  const [{ margin }, setLayoutProps] = React.useState<{
    margin: [number, number];
  }>({
    margin: [24, 24],
  });

  React.useEffect(() => {
    setLayoutProps({
      margin: matches ? [16, 16] : [24, 24],
    });
    setRowHeight(matches ? 80 : 73);
  }, [matches]);

  const onWidthChange = (
    _containerWidth: number,
    _margin: [number, number],
    cols: number,
    _containerPadding: [number, number],
  ) => {
    if (cols === 4) {
      setBreakpoint(LayoutBreakpoints.sm);
    } else {
      setBreakpoint(LayoutBreakpoints.xxs);
    }
  };

  let currentLayout = layouts[breakpoint];

  const isMobile = previewMode === 'mobile' && isPreview;
  if (isMobile) {
    currentLayout = layouts[LayoutBreakpoints.xxs];
  }

  return (
    <GridContainer className="grid-container">
      <ResponsiveGridLayout
        cols={cols}
        layouts={layouts}
        className="layout"
        isResizable={false}
        isDraggable={false}
        isDroppable={false}
        compactType="vertical"
        containerPadding={[0, 0]}
        breakpoints={gridBreakpoints}
        onWidthChange={onWidthChange}
        rowHeight={isMobile ? 80 : rowHeight}
        margin={isMobile ? [16, 16] : margin}
      >
        {generateDOM({
          theme,
          handle,
          extraData,
          dataServer: true,
          layout: currentLayout,
          disableSkeleton: true,
        })}
      </ResponsiveGridLayout>
    </GridContainer>
  );
}
