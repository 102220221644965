import NextLink from 'next/link';
import styled from '@emotion/styled';

export const DomainLink = styled(NextLink)(() => ({
  gap: '.5rem',
  display: 'flex',
  maxWidth: '100%',
  overflow: 'hidden',
  alignItems: 'center',
  textOverflow: 'ellipsis',
}));
