import React from 'react';
import { usePostHog } from 'posthog-js/react';
import { CaptureOptions, Properties } from 'posthog-js';
import { appIsProduction } from '@shared/utils/app-is-production';
import { PosthogEvents } from '@shared/constants/posthog-events';

const EngagedWithMediakitGroup: any = {
  [PosthogEvents.static]: PosthogEvents.static,
  [PosthogEvents.unsplash]: PosthogEvents.unsplash,
  [PosthogEvents.InfoCard]: PosthogEvents.InfoCard,
  [PosthogEvents.viral_mango]: PosthogEvents.viral_mango,
  [PosthogEvents.ServiceList]: PosthogEvents.ServiceList,
  [PosthogEvents.ImageChange]: PosthogEvents.ImageChange,
  [PosthogEvents.ImageAddLink]: PosthogEvents.ImageAddLink,
  [PosthogEvents.SeeAllClicked]: PosthogEvents.SeeAllClicked,
  [PosthogEvents.TextComponent]: PosthogEvents.TextComponent,
  [PosthogEvents.LinkComponent]: PosthogEvents.LinkComponent,
  [PosthogEvents.VideoComponent]: PosthogEvents.VideoComponent,
  [PosthogEvents.TitleComponent]: PosthogEvents.TitleComponent,
  [PosthogEvents.AddCompClicked]: PosthogEvents.AddCompClicked,
  [PosthogEvents.ImageComponent]: PosthogEvents.ImageComponent,
  [PosthogEvents.BackgroundType]: PosthogEvents.BackgroundType,
  [PosthogEvents.ServiceListEdit]: PosthogEvents.ServiceListEdit,
  [PosthogEvents.GalleryComponent]: PosthogEvents.GalleryComponent,
  [PosthogEvents.DeletedComponent]: PosthogEvents.DeletedComponent,
  [PosthogEvents.PartnerBtnClicked]: PosthogEvents.PartnerBtnClicked,
  [PosthogEvents.ServiceListToggle]: PosthogEvents.ServiceListToggle,
  [PosthogEvents.ServiceListAddRates]: PosthogEvents.ServiceListAddRates,
};

export const useLocalPostHog = () => {
  const posthog = usePostHog();

  return React.useMemo(
    () => ({
      capture: (
        event_name: string,
        properties?: Properties | null,
        options?: CaptureOptions,
      ) => {
        if (!appIsProduction()) {
          console.log('PostHog Event:', event_name, properties, options);

          return;
        }

        if (EngagedWithMediakitGroup[event_name]) {
          posthog?.capture(PosthogEvents.EngagedWithMediakit, {
            from: EngagedWithMediakitGroup[event_name],
          });
        }

        return posthog?.capture(event_name, properties, options);
      },
      identify: (
        new_distinct_id?: string,
        userPropertiesToSet?: Properties,
        userPropertiesToSetOnce?: Properties,
      ) => {
        if (!appIsProduction()) {
          console.log(
            'PostHog Identify:',
            new_distinct_id,
            userPropertiesToSet,
            userPropertiesToSetOnce,
          );

          return;
        }

        return posthog?.identify(
          new_distinct_id,
          userPropertiesToSet,
          userPropertiesToSetOnce,
        );
      },
      getFeatureFlag: (
        key: string,
        options?: {
          send_event?: boolean;
        },
      ): boolean | string | undefined => {
        if (!appIsProduction()) {
          console.log('PostHog getFeatureFlag:', key, options);

          return;
        }

        return posthog?.getFeatureFlag(key, options);
      },
      onFeatureFlags: (
        callback: (
          flags: string[],
          variants: Record<string, string | boolean>,
        ) => void,
      ): (() => void) => {
        if (!appIsProduction()) {
          console.log('PostHog getFeatureFlag:', callback);

          return () => {};
        }

        return posthog?.onFeatureFlags(callback);
      },
    }),
    [posthog],
  );
};
