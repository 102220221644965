import React from 'react';
import { ImageMask } from './image-mask';
import { ImageSkeleton } from './image-skeleton';
import fallback from '@shared/icons/fallback.png';
import { ImageContainer } from './image-container';
import ImageWithFallback from '@shared/ui/image-with-fallback';
import { builderCardSizes } from '@shared/constants/builderCardSizes';

export const CardFooter: React.FC<{
  imageUrl?: string;
  isLoading?: boolean;
  size: builderCardSizes;
  isLoadingImage?: boolean;
}> = ({ size, imageUrl, isLoading, isLoadingImage }) => {
  const [quality, setQuality] = React.useState(10);

  React.useEffect(() => {
    if (isLoading) {
      setQuality(10);
    }
  }, [isLoading]);

  React.useEffect(() => {
    if (isLoadingImage) {
      setQuality(10);
    }
  }, [isLoadingImage]);

  return size !== builderCardSizes.s12 ? (
    <ImageContainer size={size}>
      <ImageMask />

      {isLoading ? (
        <ImageSkeleton active></ImageSkeleton>
      ) : (
        <ImageWithFallback
          fill
          quality={quality}
          alt="Instagram Post"
          fallbackSrc={fallback}
          src={imageUrl || fallback}
          onLoad={() => setQuality(100)}
          style={{ objectFit: 'cover', objectPosition: 'center' }}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        />
      )}
    </ImageContainer>
  ) : null;
};
