import styled from '@emotion/styled';

export const ImageMask = styled('div')(() => ({
  top: 0,
  left: 0,
  zIndex: 1,
  width: '100%',
  height: '100%',
  userSelect: 'none',
  position: 'absolute',
  backgroundColor: 'transparent',
}));
